import Vue from "vue";
export default Vue.extend({
  methods: {
    addItem(): void {
      if (this.model.details.goods.length > 0) {
        const item = {
          ...this.model.details.goods[this.model.details.goods.length - 1]
        };

        this.model.details.goods.push({
          measure_unit_id: item.measure_unit_id,
          measure_unit_name: item.measure_unit_name,
          origin_country_id: item.origin_country_id,
          packing_category_id: item.packing_category_id,
          packing_category_name: item.packing_category_name
        });
        return;
      }

      this.model.details.goods.push({
        name: ""
      });
    },
    async deleteRow(index: number): Promise<void> {
      this.model.details.goods.splice(index, 1);
    },
    setMeasureUnitName(name: string, index: number): void {
      this.model.details.goods[index].measure_unit_name = name;
    },
    setMeasureUnit(event: any, index: number) {
      if (!event) {
        return;
      }

      if (event.hasOwnProperty("value")) {
        this.model.details.goods[index].measure_unit_id = event.value;
        this.setMeasureUnitName(event.text, index);
        return;
      }

      this.setMeasureUnitName(event, index);
      this.totalsKey++;
    },
    setPackingCategoryName(name: string, index: number): void {
      this.model.details.goods[index].packing_category_name = name;
    },
    setPackingCategory(event: any, index: number) {
      if (!event) {
        return;
      }

      if (event.hasOwnProperty("value")) {
        this.model.details.goods[index].packing_category_id = event.value;
        this.setPackingCategoryName(event.text, index);
        return;
      }

      this.setPackingCategoryName(event, index);
      this.totalsKey++;
    }
  }
});
