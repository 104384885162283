
import debounce from "lodash/fp/debounce";
import Vue from "vue";

export default Vue.extend({
  name: "MultipleLiveSearch",

  props: {
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    moduleName: {
      type: String,
      required: false,
      default: ""
    },
    searchFunction: {
      type: Function,
      required: true
    },
    value: {
      required: false,
      default: () => []
    },
    rules: {
      required: false,
      type: Array,
      default: () => []
    },
    errorMessage: {
      required: false,
      default: ""
    },
    allowText: {
      required: false,
      default: false,
      type: Boolean
    },
    title: {
      required: false,
      default: "",
      type: String
    }
  },

  data: () => ({
    debounce,
    debounced: null as any,
    disabledInput: false as boolean,
    search: "",
    loading: false as boolean,
    model: [] as Array<any>,
    items: [] as Array<SelectComponentInterface>,
    selectedItem: null as any,
    dialog: false as any
  }),

  watch: {
    search() {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.searchQuery);
      this.debounced();
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (this.value) {
          this.model = this.value;
        }
      }
    },
    disabled: {
      immediate: true,
      handler() {
        this.disabledInput = this.disabled;
      }
    }
  },

  computed: {
    parsedValue() {
      if (this.model.length === 1) {
        const [item] = this.model;
        return item.text;
      } else if (this.model.length > 0) {
        const [item] = this.model;
        return `${item.text} + ${this.model.length - 1} ${this.$t(
          "orders.form.fields.documents"
        )}`;
      }
      return "";
    },
    documentExists() {
      return (
        this.selectedItem &&
        this.model.some(item => item.value === this.selectedItem.value)
      );
    },
    documentExistsRule() {
      return (
        !this.documentExists || this.$t("orders.validation.document_exists")
      );
    },
    disabledAddButton() {
      return this.disabled || this.documentExists || !this.selectedItem?.value;
    }
  },

  methods: {
    async searchQuery() {
      this.loading = true;

      if (!this.selectedItem?.value) {
        this.selectedItem = null;
      }

      try {
        if (this.search) {
          const { items } = await this.searchFunction(
            String(this.search),
            this.moduleName
          );
          this.items = items;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    changeData() {
      this.model = this.model.filter((item: any) => item.value);
      this.$emit("input", this.model);
    },
    clear(index: number) {
      this.model.splice(index, 1);
      this.changeData();
      this.$emit("clear");
    },
    clearSearch(): void {
      this.search = "";
      this.items = [];
      this.$emit("clear");
    },
    selectItem(item: any) {
      this.selecteditem = { ...item };
    },
    checkInput(): void {
      this.$emit("blur", this.model);
    },
    blur() {
      if (!this.selectedItem?.value) {
        this.selectedItem = null;
        this.search = "";
      }
    },
    clearInput() {
      this.selectedItem = null;
      this.search = "";
      this.items = [];
    },
    addItem() {
      this.model.push(this.selectedItem);
      this.clearInput();
      this.changeData();
    },
    close() {
      this.dialog = false;
      this.selectedItem = null;
      this.items = [];
      this.search = "";
    },
    save() {
      this.close();
    }
  }
});
