import Vue from "vue";
export default Vue.extend({
  watch: {
    "model.details.foreign_doc_number"() {
      if (this.model.order.document_type !== "basis") {
        return;
      }

      const text = this.$t(
        `orders.form.fields.document_types.cert.${this.model.order.document_type}`,
        this.model.order.language
      );

      this.model.details.comment = `${text} ${this.model.details.foreign_doc_number}`;
    }
  },

  computed: {
    showDocumentTypeComment() {
      return (
        this.model.order.document_type &&
        ["instead_of_canceled", "retrospectively"].includes(
          this.model.order.document_type
        ) &&
        this.model.order.document_type !== "new"
      );
    },
    showParentDocument() {
      return (
        this.model.order.document_type &&
        ["instead_of_canceled", "substitution"].includes(
          this.model.order.document_type
        )
      );
    },
    isBasisDocument() {
      return (
        this.model.order.document_type &&
        this.model.order.document_type === "basis"
      );
    },
    documentTypeCommentLabel() {
      if (this.model.order.document_type === "retrospectively") {
        return this.$t("orders.form.fields.document_type_comment");
      }

      return this.$t("orders.form.fields.reason_for_cancellation");
    }
  },

  methods: {
    setParentDocuments() {
      if (this.model.order.document_type === "new") {
        return;
      }

      const text = this.$t(
        `orders.form.fields.document_types.cert.${this.model.order.document_type}`,
        this.model.order.language
      );

      this.model.details.comment = `${text} ${this.model.parent_documents
        .map((item: any) => {
          if (item.hasOwnProperty("text")) {
            return item.text;
          }

          if (item.hasOwnProperty("number")) {
            return item.number;
          }

          return item;
        })
        .join(", ")}`;
    },
    setDocumentType(value: string) {
      if (!this.showDocumentTypeComment) {
        delete this.model.details.document_type_comment;
      }

      const text = this.$t(
        `orders.form.fields.document_types.cert.${this.model.order.document_type}`,
        this.model.order.language
      );

      if (value === "new") {
        delete this.model.parent_documents;
        delete this.model.details.foreign_doc_number;
        delete this.model.details.document_type_comment;
        return;
      }

      if (value === "retrospectively") {
        delete this.model.details.document_type_comment;
        delete this.model.details.foreign_doc_number;
        this.model.details.comment = text;
        return;
      }

      if (value === "substitution") {
        delete this.model.details.document_type_comment;
        this.model.parent_documents = [];

        if (this.model.parent_documents?.length) {
          this.model.details.comment = `${text} ${this.model.parent_documents
            .map((item: any) => item.text)
            .join(", ")}`;
          return;
        }

        this.model.details.comment = `${text}`;
        return;
      }

      if (value === "instead_of_canceled") {
        delete this.model.details.foreign_doc_number;

        if (this.model.parent_documents?.length) {
          this.model.details.comment = `${text} ${this.model.parent_documents
            .map((item: any) => item.text)
            .join(", ")}`;
          return;
        }

        this.model.details.comment = `${text}`;
      }

      if (value === "basis") {
        delete this.model.parent_documents;
        delete this.model.details.document_type_comment;

        this.model.details.comment = `${text}`;
      }
    },
    setParentCard(card: any) {
      if (card.hasOwnProperty("value")) {
        this.model.parent_documents = [card];
      }

      this.setParentDocuments();
    }
  }
});
